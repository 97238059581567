<template>
  <div class="item_router">
    <button
    type="button"
      class="btn_router"
      @click.stop="onClickBtn()">
      <strong class="tit_router">{{ text }}</strong>
      <div class="area_right">
        <slot name="btn_right"/>
        <IconSvg
          v-if="isArrowRight"
          iconName="arrow_right"
          :size="24"
          iconColor="#C1C1BD"/>
      </div>
    </button>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';

export default {
  name:'ButtonRouter',
  props:{
    text: String,
    isArrowRight:{
      type: Boolean,
      default: false
    },
    routeName: String,
    routeParams: Object,
    routeQuery: Object,
  },
  components:{
    IconSvg
  },
  methods:{
    onClickBtn(e){
      if( this.routeName ){
        let routeInfo = { name: this.routeName };
        if(this.routeParams){
          routeInfo.params = this.routeParams;
        }
        if(this.routeQuery){
          routeInfo.query = this.routeQuery;
        }
        this.$router.push(routeInfo);
        return;
      }else{
        this.$emit('onClickBtn',e);
      }
    }
  }
}
</script>
<style scoped>
.item_router .btn_router{display:block;overflow:hidden;width:100%;text-align:left}
.item_router .btn_router .tit_router{display:inline-block;padding:16px 20px;font-weight:700;font-size:16px;line-height:24px;color:#111;vertical-align:top}
.item_router .btn_router .icon_arrow_right{margin:16px 20px 16px 0}

.item_router >>> .badge_number_24{margin:16px 0}
.item_router >>> .txt_router{display:inline-block;margin:17px 0;font-weight:500;font-style:14px;line-height:22px;color:#333;vertical-align:top}
.item_router + .item_router{border-top:3px solid #F3F1F1}
</style>