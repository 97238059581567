import poData from '@/constants/poData.js';
// utils
import { convertDateFormat, dateDiff } from "@/utils/dateUtils"

export default class CategoryStatisticModel {
  constructor(){
    this.isLoaded = false;
    this.paymentCategory = {
      first_transaction_year_month:'',
      last_transaction_year_month:'',
      spending:{
        item_list:[],
        top3_list:[],
        month:'',
        total_amount:0
      },
      income:{
        item_list:[],
        month:'',
        total_amount:0
      },

      current_year_month:'', // YYYY-MM

      txt_month:'',
      isControalMonthPrevDisabled:true,
      isControalMonthNextDisabled:true,
    };
    this.isSpendingOver3 = false;
    this.historyData = [];
    // {
    //   year_month:'', // YYYYMM
    //   data:'',
    // }
  }
  setData(obj, selected_year_month){
    this.paymentCategory = obj;
    this.setControalData(selected_year_month || convertDateFormat(new Date(),'YYYYMM'));
    if(obj.spending.item_list.length > 3){
      this.isSpendingOver3 = true;
      this.paymentCategory.spending.top3_list = obj.spending.item_list.slice(0,3);
    }else{
      this.isSpendingOver3 = false;
      this.paymentCategory.spending.top3_list = [];
    }
  }
  setHistoryData(obj, selected_year_month){
    const rObj = {
        year_month: selected_year_month || convertDateFormat(new Date(),'YYYYMM'), // YYYYMM
        data: obj,
    }
    this.historyData.push(rObj);
  }
  setControalData(selected_year_month){
    // current date
    const current_year_month = convertDateFormat(selected_year_month,'YYYY-MM');
    const current_year = convertDateFormat(selected_year_month,'YYYY');
    const current_format_year = convertDateFormat(selected_year_month,'YY');
    const current_format_month = convertDateFormat(selected_year_month,'M');
    this.paymentCategory.current_year_month = current_year_month;

    // txt_month
    const this_year = convertDateFormat(new Date(),'YYYY');
    const isThisYear = current_year === this_year;

    if(isThisYear){
      this.paymentCategory.txt_month = poData.c_category_month_navi_2.format(current_format_month)
    }else{
      this.paymentCategory.txt_month = poData.c_category_month_navi_1.format(current_format_year,current_format_month)
    }

    // isControalMonthDisabled
    const currentYearMonth = convertDateFormat(current_year_month+'-01','YYYY-MM-DD');
    const first_transaction_date = convertDateFormat(this.paymentCategory.first_transaction_year_month+'01','YYYY-MM-DD');
    let last_transaction_date = convertDateFormat(this.paymentCategory.last_transaction_year_month+'01','YYYY-MM-DD');
    let this_month = convertDateFormat(convertDateFormat(new Date(),'YYYY-MM')+'01','YYYY-MM-DD');
    // last_transaction_date이 이번달 보다 이전일때 이번달을 기준으로한다
    if(dateDiff(this_month,last_transaction_date) > 0){
      last_transaction_date = this_month;
    }
    this.paymentCategory.isControalMonthPrevDisabled = dateDiff(currentYearMonth,first_transaction_date) <= 0;
    this.paymentCategory.isControalMonthNextDisabled = dateDiff(currentYearMonth,last_transaction_date) >= 0;
    this.isLoaded = true;
  }
}