<template>
  <div v-if="viewModel.model.isLoaded" class="category_statistic">
    <div class="head_category_statistic">
      <div class="controal_month">
        <span class="txt_month">{{ model.txt_month }}</span>
        <button
          class="btn_controal btn_controal_prev"
          :disabled="model.isControalMonthPrevDisabled"
          @click="viewModel.onClickPrevMonth()">
          <IconSvg
            iconName="arrow_left"
            :size="24"
            :iconColor="model.isControalMonthPrevDisabled ? '#E7E5E5' : '#232323'"/>
          <span class="ir_pm">이전달</span>
        </button>
        <button
          class="btn_controal btn_controal_next"
          :disabled="model.isControalMonthNextDisabled"
          @click="viewModel.onClickNextMonth()">
          <IconSvg
            iconName="arrow_right"
            :size="24"
            :iconColor="model.isControalMonthNextDisabled ? '#E7E5E5' : '#232323'"/>
          <span class="ir_pm">다음달</span>
        </button>
      </div>
      <div :class="['tab_type',`active_tab_${viewModel.tabValue === 'spending' ? 'left' : 'right'}`]">
        <div class="inner_tab_type">
          <button
            v-for="tabItem in viewModel.tabDataList"
            :key="`tabItem${tabItem.id}`"
            :class="[
              'btn_tab',
              { active: tabItem.id === viewModel.tabValue }
            ]"
            @click="viewModel.onClickTab(tabItem.id)">{{ tabItem.text }}<span class="bar_active"></span></button>
        </div>
      </div>
      <p v-if="(tabValueData.total_amount > 0 && viewModel.tabValue === 'spending') || viewModel.tabValue === 'income'" class="desc_amount">
        <span class="txt_price">총 {{ tabValueData.total_amount | convertNumberToComma(false,'0원','원') }}</span>
        <span class="txt_desc">{{ viewModel.tabValue === 'spending' ? $poData.c_category_spend_sum : $poData.c_category_income_sum }}</span>
      </p>
      <div v-else class="box_empty">
        <p class="desc_empty">이 달에는 소비 내역이 없어요</p>
      </div>
    </div>
    <div class="body_category_statistic">
      <ListItem
        v-for="(itemData, index) in item_list"
        :key="`transItem${index}`"
        :padding="itemData.sub_title ? 24 : 16"
        :thumbNail="{
          size: 40,
          url : itemData.image_url
        }"
        :title="itemData.title"
        :rightSubText="itemData.amount | convertNumberToComma(false,'0원','원')"
        :bottomSubText="setSubTitleMarkUp(itemData.sub_title,itemData.sub_title_custom_text)"
        :backgroundColor="itemData.sub_title ? '#F0EAFA' : ''"
        @onClickItem="onClickTransList(itemData.category)">
      </ListItem>
      <Button
        v-if="viewModel.tabValue === 'spending' && viewModel.model.isSpendingOver3"
        :class="[
          'btn_show_spending',
          { 'btn_show_spending_unfold' : viewModel.isShowAllSpending  }
        ]"
        :text="viewModel.isShowAllSpending ? $poData.c_category_show_less : $poData.c_category_show_all "
        @onClickBtn="viewModel.onClickShowSpending()">
        <template v-slot:btn_right>
          <IconSvg
            iconName="arrow_fold"
            :size="24"
            iconColor="#A6A4A4"/>
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';
import ListItem from '@/components/common/list/ListItem';
import Button from '@/components/common/button/Button';
// utils
import { convertDateFormat } from "@/utils/dateUtils"
//viewModel
import CategoryStatisticViewModel from '@/components/common/categoryStatistic/viewModel/CategoryStatisticViewModel'

export default {
  name:'CategoryStatistic',
  components:{
    IconSvg,
    ListItem,
    Button,
  },
  props:{
    paymentCategory: Object,
    childUserUid: String,
    routeName: String,
    routeQuery: Object,
    isShare: {
      type: Boolean,
      default: true
    }
  },
  beforeMount(){
    this.viewModel.init(this.childUserUid);
    const transactionYearMonth = this.$route.query.transactionYearMonth;
    const transTypeValue = this.$route.query.transTypeValue;
    this.viewModel.setFilter(transactionYearMonth,transTypeValue);
  },
  watch:{
    paymentCategory(){
      this.viewModel.setPaymentCategory(this.paymentCategory)
    }
  },
  computed:{
    model(){
      return this.viewModel.model.paymentCategory
    },
    tabValueData(){
      return this.model[this.viewModel.tabValue]
    },
    item_list(){
      if(this.viewModel.tabValue === 'spending'){
        if(!this.viewModel.model.isSpendingOver3 || ( this.viewModel.model.isSpendingOver3 && this.viewModel.isShowAllSpending) ){
          return this.tabValueData.item_list
        }else{
          return this.tabValueData.top3_list
        }
      }else{
        return this.tabValueData.item_list
      }
    },
  },
  data(){
    return{
      viewModel : new CategoryStatisticViewModel()
    }
  },
  methods:{
    onClickTransList(categoryValue){
      // 자녀가 공유하지 않았을 경우 알럿
      if(!this.isShare){
        this.$store.dispatch('commonAlert/fetchAlertStart', {
          alertMsg: `<strong>${this.$poData.cannot_view_category_detail_alert_title}</strong>${this.$poData.cannot_view_category_detail_alert_desc}`,
          closeBtnText: this.$poData.cannot_view_category_detail_alert_btn,
        });
        return
      }
      if( this.routeName ){
        let routeInfo = { name: this.routeName };
        if(this.routeParams){
          routeInfo.params = this.routeParams;
        }
        routeInfo.query = this.$route.query;
        routeInfo.query.searchOption = categoryValue;
        this.$router.push(routeInfo);
        return;
      }else{
        this.$emit('onClickBtn',e);
      }
    },
    setSubTitleMarkUp(sub_title, sub_title_custom_text){
      if(!sub_title) return '';
      return sub_title.replace(sub_title_custom_text.text,`<span style="color:${sub_title_custom_text.rgb_color_code}">${sub_title_custom_text.text}</span>`)
    }
  }
}
</script>
<style scoped>
.category_statistic{padding:20px 0;border-radius:8px;background:#fff}

.head_category_statistic{padding:0 20px}

.tab_type .btn_tab{position:relative;padding:8px 0;font-weight:700;font-size:18px;line-height:28px;color:#999}
.tab_type .btn_tab.active{color:#111}
.tab_type .btn_tab + .btn_tab{margin-left:32px}
.tab_type .btn_tab.active .bar_active{position:absolute;bottom:8px;left:-1px;right:-1px;height:2px;border-radius:2px;background-color:#BEA9ED}
/* .tab_type .bar_active{position:absolute;top:0;width:50%;height:100%;border-radius:100px;background-color:#fff;transition:left 0.5s}
.active_tab_left .bar_active{left:0}
.active_tab_right .bar_active{left:50%} */

.controal_month{overflow:hidden;text-align:right}
.controal_month .txt_month{float:left;font-weight:700;font-size:20px;line-height:28px;color:#111;vertical-align:top}
.controal_month .btn_controal{display:inline-block;height:28px;padding:2px 0;vertical-align:top}
.controal_month .btn_controal + .btn_controal{margin-left:12px}

.desc_amount .txt_price{display:inline-block;font-weight:700;font-size:24px;line-height:30px;color:#111;vertical-align:top}
.desc_amount .txt_desc{display:inline-block;margin:8px 0 0 6px;font-weight:600;font-size:14px;line-height:22px;color:#666;vertical-align:top}

.body_category_statistic{margin-top:16px}

.item_list >>> .area_right .desc_item{font-weight:700;font-size:16px;line-height:24px}

.box_empty{padding:16px;border:1px dashed #A6A4A4;border-radius:4px;}
.box_empty .desc_empty{font-weight:500;font-size:14px;line-height:22px;color:#999}

.btn_show_spending{display:block;width:100%;height:auto;padding:4px 20px;font-weight:700;font-size:16px;line-height:24px;color:#111}
.btn_show_spending_unfold >>> .icon_arrow_fold{transform:scaleY(-1)}
.tab_type,
.desc_amount,
.box_empty,
.btn_show_spending{margin-top:16px}
.item_list + .item_list{margin-top:12px}


</style>