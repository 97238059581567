<template>
  <div
    :class="[
      'box_section',
      'box_section_wallet',
      { active : isActiveWalletDetail}
    ]">
    <p class="desc_total_amount" @click="onClickWalletDetail()">
      <strong class="tit_total_amount">{{ title }}</strong>
      <span class="txt_total_amount">
        <div class="txt_price">{{ amount | convertNumberToComma(false,'0원','원') }}</div>
        <IconSvg
          iconName="arrow_fold"
          :size="24"
          iconColor="#A6A4A4"/>
      </span>
    </p>
    <div class="group_box">
      <div class="area_box_money">
        <div class="box_money_item box_money" @click="onClickMoneyInfo()">
          <span class="badge_word">M</span>
          <strong class="tit_money_item">{{ $poData.child_details_wallet_money }}</strong>
          <p class="desc_amount">
            <strong class="txt_amount">{{ money_amount | convertNumberToComma(false,'0','원') }}</strong>
          </p>
        </div>
        <div class="box_money_item box_money" @click="onClickPointInfo()">
          <span class="badge_word">P</span>
          <strong class="tit_money_item">{{ $poData.child_details_wallet_point }}</strong>
          <p class="desc_amount">
            <strong class="txt_amount">{{ point_amount | convertNumberToComma(false,'0','원') }}</strong>
          </p>
        </div>
      </div>
      <slot name="inFold"/>
    </div>
    <slot/>
    <Layer
      :title="$poData.money_helpersheet_title"
      v-if="isMoneyInfoPopup"
      @onClickClose="onClickMoneyInfoClose()">
      <p class="desc_drop" v-html="$poData.money_helpersheet_desc"></p>
    </Layer>
    <Layer
      :title="$poData.point_helpersheet_title"
      v-if="isPointInfoPopup"
      @onClickClose="onClickPointInfoClose()">
      <p class="desc_drop" v-html="$poData.point_helpersheet_desc"></p>
    </Layer>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg';
import Layer from '@/components/layout/popup/Layer';

export default {
  name: 'WalletHead',
  components:{
    IconSvg,
    Layer
  },
  props:{
    title: String,
    amount: Number,
    money_amount: Number,
    point_amount: Number
  },
  data(){
    return{
      isActiveWalletDetail: false,
      isMoneyInfoPopup: false,
      isPointInfoPopup: false,
    }
  },
  methods:{
    onClickWalletDetail(){
      this.isActiveWalletDetail = !this.isActiveWalletDetail;
    },
    onClickMoneyInfo(){
      this.isMoneyInfoPopup = true;
    },
    onClickMoneyInfoClose(){
      this.isMoneyInfoPopup = false;
    },
    onClickPointInfo(){
      this.isPointInfoPopup = true;
    },
    onClickPointInfoClose(){
      this.isPointInfoPopup = false;
    },
  }
}
</script>

<style scoped>

/* wallet */
.box_section{overflow:hidden;padding:20px 0;border-radius:8px;background-color:#fff}
.box_section.box_section_wallet{padding:20px}

.badge_word{display:inline-block;width:24px;height:24px;border-radius:100%;font-family:'Poppins';font-weight:700;font-size:14px;line-height:24px;color:#111;text-align:center;background-color:#BEA9ED;vertical-align:top}

.desc_total_amount{overflow:hidden}
.desc_total_amount .tit_total_amount{display:block;font-weight:700;font-size:16px;line-height:24px;color:#999}
.desc_total_amount .txt_total_amount{display:block;margin-top:4px;}
.desc_total_amount .txt_price{display:inline-block;font-weight:700;font-size:24px;line-height:30px;color:#111;vertical-align:top}
.desc_total_amount .icon_arrow_fold{display:inline-block;margin:3px 0 3px 2px;vertical-align:top}

.group_box{overflow:hidden;max-height:0;transition:max-height 0.2s ease-in}
.group_box .area_box_money{display:flex;flex-wrap:nowrap;padding-top:32px}
.group_box .box_money_item{display:inline-block;width:calc(50% - 8px);padding:14px;border-radius:8px;background-color:#F3F1F1;vertical-align:top;box-sizing:border-box}
.group_box .box_money_item + .box_money_item{margin-left:16px}
.group_box .tit_money_item{display:inline-block;margin:1px 0 1px 6px;font-weight:600;font-size:14px;line-height:22px;vertical-align:top}
.group_box .desc_amount{margin-top:12px;font-weight:700;font-size:16px;line-height:24px;color:#111;vertical-align:top}
.group_box + button{margin-top:32px}

.box_section_wallet.active .group_box{max-height:500px}
.box_section_wallet.active .desc_total_amount .icon_arrow_fold{transform:scaleY(-1)}
</style>