<template>
  <div
    :class="['badge_comm', `badge_${type}`, { 'badge_round' : isRound }]"
    :style="cssProps">{{ value }}</div>
</template>

<script>
export default {
  name:'Badge',
  props:{
    value: String,
    type: String, // sub1, sub2, lightOrange, lightPurple, black, red, orange, purple, blue
    isRound: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    isOverHundred(){
      return Number(this.value) > 99
    },
    cssProps(){
      let ovalPadding = 6;
      switch(this.size){
        case 20 : { ovalPadding = 6; break; }
        case 24 : { ovalPadding = 8; break; }
      }
      return{
        '--size' : `${this.size}px`,
        '--ovalPadding' : `0 ${ovalPadding}px`,
        'border-radius': `${this.size/2}px`
      }
    }
  },
}
</script>
<style scoped>
.badge_comm{display:inline-block;padding:4px 8px;border-radius:2px;font-weight:600;font-size:12px;line-height:16px;color:#111;vertical-align:top;box-sizing:border-box}

.badge_comm.badge_round{border-radius:12px}

.badge_sub1{background-color:#F3F1F1}
.badge_sub2{background-color:#F1EEE1}
.badge_lightOrange{background-color:#FBAE8A}
.badge_lightPurple{background-color:#D8CBF4}
.badge_black{background-color:#232323;color:#fff}
.badge_red{background-color:#E23C3C;color:#fff}
.badge_orange{background-color:#F98E5A}
.badge_purple{background-color:#BEA9ED}

.badge_round.badge_red{background-color:#FDEFEF;color:#E23C3C}
.badge_round.badge_purple{background-color:#F0EAFA;color:#8F69DD}
.badge_blue{background-color:#E6F3FE;color:#579BF5}
</style>