export function makeQueryStringByObject ( obj ) {
  let queryArray = Object.keys(obj).reduce(function(a, k){
    const value = encodeURIComponent(obj[k]);
    const query = k + '=' + value;
    if(value != '' && value != 'null' && value != 'undefined'){
      a.push(query);
    }
    return a;
  }, []);
  let str = '?' + queryArray.join('&');
  return str;
};