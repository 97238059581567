//model
import CategoryStatisticModel from '@/components/common/categoryStatistic/model/CategoryStatisticModel'
import { convertDateFormat, dateDiff, calcDate } from '@/utils/dateUtils';
import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import store from '@/store/index';
import router from '@/router';

export default class CategoryStatistic {
  constructor() {
    this.model = new CategoryStatisticModel();
    this.isHasFilter = false;
    this.tabDataList = [
      {
        id:'spending',
        text:'소비'
      },
      {
        id:'income',
        text:'수입'
      }
    ],
    this.tabValue = 'spending', // spending || income
    this.childUid = '';
    this.isShowAllSpending = false;
  }
  init(childUid){
    if(childUid){
      this.childUid = childUid;
    }
  }
  setPaymentCategory(paymentCategory){
    if(!this.isHasFilter){
      this.model.setData(paymentCategory);
      this.model.setHistoryData(paymentCategory);
    }
  }
  setFilter(transactionYearMonth,transTypeValue){
    if(transactionYearMonth){
      let this_month = convertDateFormat(convertDateFormat(new Date(),'YYYY-MM')+'01','YYYY-MM-DD');
      let filter_month = convertDateFormat(convertDateFormat(transactionYearMonth,'YYYY-MM')+'01','YYYY-MM-DD');
      if(dateDiff(this_month,filter_month) != 0){
        this.isHasFilter = true;
        this.getPaymentCategory(transactionYearMonth)
      }
    }
    if(transTypeValue){
      this.tabValue = transTypeValue
    }else{
      this.setQuery({ 'transTypeValue' : this.tabValue })
    }
  }
  setQuery(addQuery){
    let currentQuery = JSON.parse(JSON.stringify(router.app._route.query));
    let newQuery = Object.fromEntries(Object.entries(Object.assign(currentQuery,addQuery)).filter(([_, v]) => v != '' && v != undefined  && v != 'undefined' ));
    router.replace({'query': newQuery}).catch(()=>{});
  }
  
  onClickTab(tabItemId){
    this.tabValue = tabItemId;
    this.setQuery({ 'transTypeValue' : tabItemId })
  }

  onClickPrevMonth(){
    const yearMonth = calcDate(this.model.paymentCategory.current_year_month,-1,'month','YYYYMM');
    this.checkHistory(yearMonth)
  }
  onClickNextMonth(){
    const yearMonth = calcDate(this.model.paymentCategory.current_year_month,+1,'month','YYYYMM');
    this.checkHistory(yearMonth)
  }

  onClickShowSpending(){
    this.isShowAllSpending = !this.isShowAllSpending;
  }

  checkHistory(yearMonth){
    const diffHistory = this.model.historyData.find(item => item.year_month === yearMonth);
    this.setQuery({ 'transactionYearMonth' : yearMonth })
    if(diffHistory){
      this.model.setData(diffHistory.data, yearMonth)
    }else{
      this.getPaymentCategory(yearMonth)
    }
  }

  getPaymentCategory(yearMonth){
    const query = {
      'yearMonth' : yearMonth
    }
    const categoryStatisticApiPath = this.childUid ? apiPath.CATEGORY_STATISTIC_CHILD.format(this.childUid) : apiPath.CATEGORY_STATISTIC
    const path = `${categoryStatisticApiPath}${makeQueryStringByObject(query)}`;

    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.model.setData(resultData, yearMonth);
      this.model.setHistoryData(resultData, yearMonth);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}