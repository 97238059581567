var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'item_list',
    { 'item_bg' : _vm.backgroundColor }
  ],style:(_vm.cssProps),on:{"click":function($event){return _vm.$emit('onClickItem')}}},[_c('div',{staticClass:"inner_item_list"},[(_vm.thumbNail)?_c('div',{staticClass:"thumb_item",style:({
        'background-image':`url(${_vm.thumbNail.url})`,
        'background-color': _vm.thumbNail.url ? 'none' : '#F3F1F1'
      })}):_vm._e(),_c('div',{staticClass:"area_left"},[_c('div',{staticClass:"inner_left"},[_c('div',{staticClass:"head_left"},[(_vm.leftBadge && _vm.leftBadge.value && _vm.leftBadge.value != 'null')?_c('Badge',{attrs:{"value":_vm.leftBadge.value,"type":_vm.leftBadge.type,"isRound":_vm.leftBadge.isRound}}):_vm._e(),_c('strong',{staticClass:"tit_item"},[_vm._v(_vm._s(_vm.title))])],1),_c('div',{staticClass:"body_left"},[(_vm.leftSubText)?_c('p',{class:[
              'desc_item',
              _vm.leftSubTextColor ? `tc_${_vm.leftSubTextColor}` :''
            ]},[_vm._t("leftSubSlot"),_vm._v(_vm._s(_vm.leftSubText))],2):_vm._e()])])]),(_vm.hasRightArea)?_c('div',{staticClass:"area_right"},[_c('div',{staticClass:"inner_right"},[_c('div',{staticClass:"head_right"},[(_vm.rightBadge)?_c('Badge',{attrs:{"value":_vm.rightBadge.value,"type":_vm.rightBadge.type,"isRound":_vm.rightBadge.isRound}}):_vm._e(),(_vm.rightSubText)?_c('p',{class:[
              'desc_item', 
              _vm.isRightSubTextColor ? `tc_${_vm.isRightSubTextColor}` :''
            ]},[_vm._v(_vm._s(_vm.rightSubText))]):_vm._e()],1),_c('div',{staticClass:"body_right"},[_vm._t("rightSlot")],2)])]):_vm._e()]),(_vm.bottomSubText)?_c('div',{staticClass:"area_bottom"},[_c('p',{staticClass:"desc_bottom",domProps:{"innerHTML":_vm._s(_vm.bottomSubText)}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }