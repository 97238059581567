<template>
  <div 
    :class="[
      'item_list',
      { 'item_bg' : backgroundColor }
    ]"
    :style="cssProps"
    @click="$emit('onClickItem')">
    <div
      class="inner_item_list">
      <div
        v-if="thumbNail"
        class="thumb_item"
        :style="{
          'background-image':`url(${thumbNail.url})`,
          'background-color': thumbNail.url ? 'none' : '#F3F1F1'
        }"></div>
      <div class="area_left">
        <div class="inner_left">
          <div class="head_left">
            <Badge
              v-if="leftBadge && leftBadge.value && leftBadge.value != 'null'"
              :value="leftBadge.value"
              :type="leftBadge.type"
              :isRound="leftBadge.isRound"/>
            <strong class="tit_item">{{ title }}</strong>
          </div>
          <div class="body_left">
            <p
              v-if="leftSubText"
              :class="[
                'desc_item',
                leftSubTextColor ? `tc_${leftSubTextColor}` :''
              ]"><slot name="leftSubSlot"/>{{ leftSubText }}</p>
          </div>
        </div>
      </div>
      <div v-if="hasRightArea" class="area_right">
        <div class="inner_right">
          <div class="head_right">
            <Badge
              v-if="rightBadge"
              :value="rightBadge.value"
              :type="rightBadge.type"
              :isRound="rightBadge.isRound"/>
            <p v-if="rightSubText" :class="[
                'desc_item', 
                isRightSubTextColor ? `tc_${isRightSubTextColor}` :''
              ]">{{ rightSubText }}</p>
          </div>
          <div class="body_right">
            <slot name="rightSlot"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="bottomSubText" class="area_bottom">
      <p class="desc_bottom" v-html="bottomSubText"></p>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/common/badge/Badge';
import { mapGetters } from 'vuex';

export default {
  name:'ListItem',
  components:{
    Badge,
  },
  props:{
    padding: {
      type: Number,
      default: 16
    },
    paddingRight: {
      type: Number,
      default: 12
    },
    thumbNail: Object, // url, size
    title: String,
    leftSubText: String,
    leftSubTextColor: String,
    rightSubText: String,
    leftBadge: Object, // value, type, isRound
    rightBadge: Object, // value, type, isRound
    isRightSubTextColor: String,
    bottomSubText: String,
    backgroundColor: String,
  },
  computed:{
    hasRightSlot(){
      return this.$slots['rightSlot']
    },
    hasRightArea(){
      return this.rightBadge || this.rightSubText || this.hasRightSlot
    },
    ...mapGetters('commonViewport', [ 'getWindowWidth' ]),
    cssProps(){
      let paddingTopBottom = this.padding;
      let paddingLeft = 20;
      let paddingRight = 20;
      if(this.thumbNail){
        paddingLeft = (this.thumbNail.size || 40) + 20 + this.paddingRight;
      }
      if(this.backgroundColor){
        paddingLeft -= 8;
        paddingRight -= 8;
      }
      let rObj ={
        '--paddingTop' :`${paddingTopBottom}px`,
        '--paddingInner' : `${paddingTopBottom}px ${paddingRight}px ${paddingTopBottom}px ${paddingLeft}px`,
        '--areaLeftWidth' : this.rightSubText ? '56%' : 'auto',
        '--areaRightWidth' : `${( this.getWindowWidth - paddingLeft - paddingRight ) * 0.44 }px`,
        'background-color' : this.backgroundColor || '#FFF',
      }
      if(this.thumbNail){
        rObj['--thumbNailSize'] = `${this.thumbNail.size || 40}px`;
        rObj['--thumbNailMarginTop'] = `-${this.thumbNail.size /2}px`;
      }
      if(this.backgroundColor){
        rObj['margin'] = '0 8px';
      }
      if(this.bottomSubText){
        rObj['padding'] = `0 0 12px 0`;
      }
      return rObj;
    }
  },
}
</script>
<style scoped>
.item_list .inner_item_list{display:flex;position:relative;padding:var(--paddingInner);overflow:hidden}
.item_list .thumb_item{position:absolute;left:20px;top:50%;width:var(--thumbNailSize);height:var(--thumbNailSize);margin-top:var(--thumbNailMarginTop);border-radius:100%;background-size:cover;background-repeat:no-repeat;background-position:center}
.item_list .tit_item{display:inline-block;font-weight:700;font-size:16px;line-height:24px;color:#111;vertical-align:top}

.item_list .area_left{flex:1 0;width:var(--areaLeftWidth);padding-right:6px;overflow:hidden;box-sizing:border-box}
.item_list .area_left:last-child{width:auto}
.item_list .area_left:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.item_list .inner_left{display:inline-block;max-width:100%;vertical-align:middle;white-space:normal}
.item_list .area_left .head_left{display:inline-block;max-width:100%;vertical-align:top}
.item_list .area_left .head_left .tit_item{display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}
.item_list .area_left .head_left .badge_comm{float:right;margin:2px 0 2px 4px;padding:2px 8px}
.item_list .area_left .desc_item{display:block;overflow:hidden;margin-top:2px;font-weight:500;font-size:12px;line-height:16px;color:#999;white-space:nowrap;text-overflow:ellipsis}

.item_list .area_right{max-width:var(--areaRightWidth);min-width:92px;overflow:hidden;font-size:0;white-space:nowrap;text-align:right}
.item_list .area_right:after{display:inline-block;height:100%;vertical-align:middle;content:''}
.item_list .inner_right{display:inline-block;vertical-align:middle;white-space:normal}
.item_list .area_right .head_right{display:inline-block;max-width:100%;vertical-align:top}
.item_list .area_right .desc_item{font-weight:600;font-size:14px;line-height:22px;color:#111}
.item_list .area_right .txt_date{margin-top:2px;font-weight:500;font-size:12px;line-height:16px;color:#999}

.item_list.item_bg{border-radius:4px}
.item_list.item_bg .thumb_item{left:12px}
.item_list .area_bottom{clear:left;margin:0 12px;padding:12px 16px;border-radius:4px;background-color:#FFF}
.item_list .desc_bottom{font-weight:500;font-size:14px;line-height:22px;color:#000}
</style>